import React from 'react';
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Social from "../components/social.js"
import Layout from "../components/LayoutAlt.js"
import MARRIAGE from "../images/marriage.jpg"
import YOUTH from "../images/youth.jpg"
import KIDS from "../images/kids.jpg"




const Family = () =>{

    return(
        <Layout>

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="The Families are important. Get information on building better Families?" />
        <title>ReaCH | Family</title>
        <link rel="canonical" href="https://reachun.org/family" />
      </Helmet>

          <section className="sm:w-3/4 sm:ml-1/8 xs:mt-10 sm:mt-20 mb-20 xs:px-2 sm:px-0">
          <div className="grid xs:grid-cols-1 sm:grid-cols-2">
             <div className="xs:pt-4 xs:pb-4 sm:pb-0 sm:pt-10 lg:pt-10 bg-blue-alt text-white ">
             <p className="font-playfairdisplay font-bold xs:text-6xl xl:text-7xl 2xl:text-9xl xs:px-4 md:px-10 xl:px-20 mb-4">Family Matters</p>
             <p className="font-montserrat font-bold lg:text-lg xl:text-2xl xs:px-4 md:px-10 xl:px-20 mb-2">Joshua 24:14-15</p>
             <p className="font-opensans font-light text-sm  xl:px-20 mb-2 xs:hidden 2xl:block">14 Now therefore fear the Lord, and serve him in sincerity and in truth: and put away the gods which your fathers served on the other side of the flood, and in Egypt; and serve ye the Lord.</p>
             <p className="font-opensans font-light xs:hidden lg:block text-sm md:px-10 xl:px-20">15 And if it seem evil unto you to serve the Lord, choose you this day whom ye will serve; whether the gods which your fathers served that were on the other side of the flood, or the gods of the Amorites, in whose land ye dwell: but as for me and my house, we will serve the Lord.</p>
           </div>
             <Link to="/family/mw">
             <div className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"><img src={MARRIAGE} alt="family" className="object-cover w-full xl:h-96 2xl:h-108 familyImg" /></div>
             </Link>
             <Link to="/family/youth">
             <div className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"><img src={YOUTH} alt="family" className="object-cover w-full xl:h-96 2xl:h-108 familyImg" /></div>
             </Link>
             <Link to="/family/kids">
             <div className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"><img src={KIDS} alt="family" className="object-cover w-full xl:h-96 2xl:h-108 familyImg" /></div>
             </Link>
          </div>
          </section>

        <Social />
        </Layout>
        
    )
}

export default Family